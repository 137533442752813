

    .container-tours {
        padding-top: 0px;
    }

    .container-information-tours {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px 30px 20px 30px;
    }

    .page-header {
        height: 100vh;
        max-height: 1050px;
        padding: 0;
        color: #fff;
        position: relative;
        background-position: center center;
        background-size: cover;
    }

    .page-header .page-header-image {
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .page-header footer {
        position: absolute;
        bottom: 0;
        width: 100%
    }

    .page-header .container-title {
        height: 100%;
        z-index: 1;
        text-align: center;
        position: relative
    }

    .page-header .container-title>.content-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        padding: 0 15px;
        color: #fff;
        width: 100%;
        max-width: 880px
    }

    .page-header .category,.page-header .description {
        color: #ffffff80
    }

    .page-header.page-header-small {
        min-height: 60vh;
        max-height: 440px;
    }

    .page-header:after,.page-header:before {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: ""
    }

    .page-header:before {
        background-color: #00000080;
    }

    .page-header[filter-color=orange] {
        background: rgba(44,44,44,.2);
        background: linear-gradient(0deg,rgba(44,44,44,.2),rgba(224,23,3,.6))
    }

    .page-header .container-title {
        z-index: 2
    }

    .title {
        font-weight: 700;
    }

    h1,.h1 {
        font-size: 3.5em;
        line-height: 1.15em;
        margin-bottom: 30px
    }

    h2 {
        font-size: 30px;
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 25px;
        line-height: 1.15em;
    }

    h4, .h4 {
        font-size: 1.2em;
        line-height: 1.45em;
        margin-top: 30px;
        margin-bottom: 15px;
        font-weight: 400;
    }

    .separator {
        width: 100%;
        border-bottom: 1px solid #b2b2b2;
        margin: 10px 0;
    }

    .carousel-list {
        width: 100%;
        height: 257px;
    }

    p {
        line-height: 1.61em;
    }

    .container-offer-icon {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .container-gallery-tours{
        display: flex;
        gap: 10px;
        justify-content: center;
    }
