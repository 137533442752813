.container-contact {
    padding-top: 100px;
    padding-bottom: 100px;
}

.container-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.container-information-tours {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 30px 20px 30px;
}

.container-title {
    display: flex;
    justify-content: center;
}

.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.button-send {
    width: 250px;
}

.container-email {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Estilos para pantallas de escritorio */
@media (min-width: 1200px) {
    .button-send {
        width: 250px;
    }
}
  
  /* Estilos para pantallas de laptop */
@media (min-width: 992px) and (max-width: 1199px) {
    .button-send {
        width: 250px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .button-send {
        width: 100%;
    }
}

/* Estilos para pantallas de tablet */
@media (min-width: 576px) and (max-width: 767px) {
    .button-send {
        width: 100%;
    }
}

/* Estilos para pantallas de celular */
@media (max-width: 575px) {
    .button-send {
        width: 100%;
    }
}

.container-contact-me {
    padding-top: 20px;
    // border: 1px solid;
    width: 100%;
}

.container-main-me {
    display: flex;
    gap: 10px;
}