.container-title-sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 12px;
}

.border-bottom {
    background-color: #035f8e;
    height: 5px;
    width: 100%;
    border-radius: 20px;
}

.title-sections {
    font-size: 2.5em;
    color: #3f3f3f;
    font-weight: 700;
}