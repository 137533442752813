.titles h3 {
    color: #fdb40f;
}

.container-packages {
    display: flex;
    flex-direction: column;
}
.packages {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
}

.packages-title {
    color: #3f3f3f;
    font-size: 1.17em;
    font-weight: bold;
}

.card-content {
    height: 350px;
    overflow-y: auto;
}
// .card-boat-title {

// }