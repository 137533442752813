.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.container-home-welcome {
    width: 100%;
}

/* Estilos para pantallas de escritorio */
@media (min-width: 1200px) {
    .container-home-welcome {
        height: 100vh;
    }
}
  
  /* Estilos para pantallas de laptop */
@media (min-width: 992px) and (max-width: 1199px) {
    .container-home-welcome {
        height: 100vh;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container-home-welcome {
        height: 100vh;
    }
}

.container-home-tours {
    background-color: #f9f9f9;
    width: 100%;
    padding-bottom: 20px;
}

.container-logo-text {
    display: flex;
    align-items: center;
}

.container-logo-home {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-text-home {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 18px;

    background-color: #FFF;
    opacity: 0.7;
    color: #000;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 15px;
    border-radius: 20px;
}

.background-welcome-text {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.box-shadow-cards {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    box-shadow: 0px 2px 1px -2px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.dt-sc-button, .button {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    line-height: normal;
    display: inline-block;
    margin: 10px 0px 0px;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 3px;
    color: #fff;
    border: none;
}

.dt-sc-button.too-small {
    font-size: 13px;
    padding: 7px 13px;
}

.text-limited {
    display: -webkit-box;
    -webkit-line-clamp: 3; // Número máximo de líneas
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }