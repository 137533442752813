.MuiAppBar-root {
    background-color: #035f8e !important;
  
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 1000 !important;
}
  
.links {
  color: #FFF;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.name {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  margin-right: 16px;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: .1rem;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 5px;
}